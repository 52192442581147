<template>
  <div>
    <v-pagination
      v-if="gridApi || manual"
      v-model="page"
      @update:modelValue="changePage"
      :pages="
        !manual
          ? gridApi.paginationGetTotalPages()
          : Math.ceil(total / countOnPage)
      "
      :range-size="1"
      active-color="transparent"
    />

    <div
      v-if="(gridApi && gridApi.paginationGetTotalPages()) || (manual && total)"
      class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{
          !manual
            ? gridApi.paginationProxy.bottomDisplayedRowIndex + 1
            : countOnPage * (page - 1) + count
        }}
        из
        {{ !manual ? gridApi.paginationGetRowCount() : total }}
      </p>
      <p>
        Страница {{ page }} из
        {{
          !manual
            ? gridApi.paginationGetTotalPages()
            : Math.ceil(total / countOnPage)
        }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
export default {
  name: "table-pagination",
  components: {
    VPagination,
  },
  props: {
    gridApi: {
      type: Object,
      required: false,
    },
    countOnPage: {
      required: true,
    },
    activeFilter: {
      required: false,
    },
    manual: {
      type: Boolean,
      required: false,
    },
    total: {
      type: Number,
      required: false,
    },
    count: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {
    const page = ref(1),
      gridApi = toRef(props, "gridApi"),
      countOnPage = toRef(props, "countOnPage"),
      manual = toRef(props, "manual");

    const changePage = (page) => {
      if (!manual.value) {
        gridApi.value.paginationGoToPage(page - 1);
        return;
      }
      context.emit("changePage", page);
    };

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    return {
      page,
      changePage,
    };
  },
};
</script>

<style>
</style>