export default {
	value: [
		{
			field: "id",
			hide: true,
		},
		{
			field: "check",
			headerName: "",
			checkboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			minWidth: 50,
			maxWidth: 50,
			flex: 0,
		},
		{
			field: "photo",
			headerName: "Фото",
			maxWidth: 100,
			flex: 0,
			cellRenderer: "ImageRenderer",
		},
		{
			field: "name",
			headerName: "Имя",
			wrapText: true,
			minWidth: 305,
			cellRenderer: "TextLinkRenderer",
			cellRendererParams: {
				source: "staff",
				end: "edit",
			},
		},
		{
			field: "email",
			headerName: "Email",
			wrapText: true,
			minWidth: 255,
		},
		{
			field: "groups",
			headerName: "Группы",
			wrapText: true,
			minWidth: 235,
			cellRenderer: "UserGroupsRenderer",
		},
		{
			field: "createdAt",
			headerName: "Дата регистрации",
			minWidth: 195,
		},
		{
			field: "onlineFrom",
			headerName: "Дата последней авторизации",
			minWidth: 180,
		},

		{
			field: "role",
			headerName: "Роль",
			minWidth: 160,
			maxWidth: 160,
			flex: 0,
		},
		{
			field: "eiToken",
			headerName: "Зайти под учётную запись",
			cellRenderer: "AuthorizationRenderer",
			flex: 0,
		},
		{
			field: "edit",
			width: 70,
			headerName: "",
			flex: 0,
			cellRenderer: "EditRenderer",
			cellRendererParams: {
				source: "staff",
				end: "edit",
			},
		},
	],
}