<template>
  <div>
    <router-link
      v-if="params.source && params.end"
      :to="`/${params.source}/${params.value}/${params.end}`"
    >
      <i class="fal fa-pen"></i>
    </router-link>
    <router-link
      v-else-if="params.source"
      :to="`/${params.source}/${params.value}`"
    >
      <i class="fal fa-pen"></i>
    </router-link>

    <router-link v-if="params.groupPage" :to="`/group/${params.value}`">
      <i class="fal fa-users"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
