<template>
  <div class="form-group">
    <label v-if="label" class="form-group__label"> {{ label }} </label>
    <div class="flex flex-row flex-xs-column-reverse align-item-center">
      <div v-if="showFileName" class="form-group__file-name">
        {{ fileName }}
      </div>
      <div class="form-group__input-container">
        <input
          tabindex="-1"
          @change="loadFiles"
          class="form-control"
          type="file"
          :id="generatedId"
          :error="name"
          :accept="accept"
          ref="fileInput"
          :multiple="multiple"
        />
        <label class="btn btn-accent" :for="generatedId">
          <i class="fas fa-upload"></i>
          <span>Выбрать файл</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "@vue/runtime-core";
export default {
  name: "custom-file-loader",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    accept: {
      type: String,
      default: "image/png, image/jpeg",
    },
    showFileName: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let generatedId = ref("file"),
      fileInput = ref(),
      fileName = ref("");

    const generateId = () => {
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };
    const loadFiles = (event) => {
      fileName.value = event.target.files[0].name;
      context.emit("loading", event.target.files);
      event.target.value = null;
    };
    const clickOnInput = () => {
      fileInput.value.click();
    };
    const clearInput = () => {
      fileInput.value.value = "";
    };

    onBeforeMount(() => {
      generateId();
    });

    return {
      generateId,
      generatedId,
      loadFiles,
      clickOnInput,
      fileInput,
      clearInput,
      fileName,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin: 10px 0;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  justify-content: center;

  flex-direction: column;
  &__label {
    padding: 15px;
    // flex: 0 0 20%;
    word-break: break-word;
  }

  i {
    margin-right: 7px;
  }

  .form-control {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__file-name {
    margin-right: 15px;
    color: var(--hover-color);
    max-width: 227px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 973px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    &__label {
      flex: 1;
    }
    &__input-container {
      padding-left: 15px;
      flex: 1;
      font-size: 14px;
    }

    .btn {
      font-size: 15px;
    }
  }

  @media (max-width: 767px) {
    &__file-name {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  @media (max-width: 425px) {
    .form-group {
      width: 100%;
      align-items: center;
      margin-bottom: 20px;

      &__input-container {
        text-align: center;
        padding-left: 0;
        width: auto;
      }
    }
  }
}
</style>