<template>
  <div class="groups-renderer">
    <router-link
      :key="group.id"
      v-for="group of params.value"
      :to="`/group/${group.id}`"
      >{{ group.name }}<br
    /></router-link>
  </div>
</template>

<style lang="scss" scoped>
a {
  &::before {
    content: "";
    background-color: var(--main-color);
    height: 5px;
    width: 5px;
    margin-right: 5px;
    margin-bottom: 2px;
    display: inline-block;
  }
}
</style>
