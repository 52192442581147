<template>
  <div v-if="params.source && params.end">
    <router-link
      :to="`/${params.source}/${params.node.data.id}/${params.end}`"
      >{{ params.value }}</router-link
    >
  </div>
  <div v-else-if="params.source">
    <router-link :to="`/${params.source}/${params.node.data.id}`">{{
      params.value
    }}</router-link>
  </div>
</template>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;
  &:hover {
    color: var(--hover-color);
  }
}
</style>